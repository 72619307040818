import assert from 'react-share/lib/utils/assert';
import createShareButton from 'react-share/lib/hocs/createShareButton';
import objectToGetParams from 'react-share/lib/utils/objectToGetParams';

function xLink(url: string, { title }: { title?: string }) {
  assert(url, 'x.com');
// intent/postだと𝕏アプリが起動しない（Android版で確認。iOS未チェック。WebはOK）
  return (
    'https://x.com/intent/tweet' +
    objectToGetParams({
      url:url,
      text: title,
    })
  );
}

const XShareButton = createShareButton<{ title?: string }>(
  'x',
  xLink,
  props => ({
    title: props.title,
  }),
  {
    windowWidth: 600,
    windowHeight: 400,
  },
);

export default XShareButton;